import React, { useState, useEffect } from "react";
import Slider from '@mui/material/Slider';
import ssbclogo from './assets/ssbc-logo-all-white.png';
import './App.css';

function Calculator() {
    const [notes, setNotes] = useState("");
    const [screen, setScreen] = useState("current");
    const [data, setData] = useState({
        "# of days I work per week": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 7, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "", "valid": true},
        "Avg # of daily clients I have": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 20, "step": .5, "style":{ style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 }, "suffix": "", "valid": true},
        "# of weeks I work per year": {"Current": "", "New": 0, "Difference": 0, "min": 30, "max": 52, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "", "valid": true},
        "# weeks paid vacation annually": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 8, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "", "valid": true},
        "My avg service ticket $$": {"Current": "", "New": 0, "Difference": 0, "min": 30, "max": 300, "step": 5, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }, "suffix": "", "valid": true},
        "My service commission %": {"Current": "", "New": 0, "Difference": 0, "min": 40, "max": 55, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "%", "valid": true},
        "My avg retail $ per week": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 1000, "step": 10, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}, "suffix": "", "valid": true},
        "My avg retail commission %": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 30, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "%", "valid": true},
        "My avg tip %": {"Current": 15, "New": 15, "Difference": 0, "min": 0, "max": 30, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "%", "valid": true},
    });

    const [profileData, setProfileData] = useState ({
        "Daily Service Goal": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "My avg service ticket $$": {"Current": "", "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Guest Count": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 }},
        "Daily Retail Goal": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Service Commision": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Vacation": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Service Tips": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Retail Bonus": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Total Income": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
    });

    useEffect(() => {
        const calculateServiceCommissionCurrent = () => {
            const daysWorked = data["# of days I work per week"].Current;
            const clientsServed = data["Avg # of daily clients I have"].Current;
            const weeksWorked = data["# of weeks I work per year"].Current;
            const serviceTicketAverage = data["My avg service ticket $$"].Current;
            const commissionPercentage = data["My service commission %"].Current/100;
            return daysWorked * clientsServed * weeksWorked * serviceTicketAverage * commissionPercentage;
        };

        const calculateServiceCommissionNew = () => {
            const daysWorked = data["# of days I work per week"].New;
            const clientsServed = data["Avg # of daily clients I have"].New;
            const weeksWorked = data["# of weeks I work per year"].New;
            const serviceTicketAverage = data["My avg service ticket $$"].New;
            const commissionPercentage = data["My service commission %"].New/100;
            return daysWorked * clientsServed * weeksWorked * serviceTicketAverage * commissionPercentage;
        };

        const calculateVacationCurrent = () => {
            const weeksWorked = data["# of weeks I work per year"].Current;
            if (weeksWorked === 0) {
                return 0;
            }
            return ((calculateServiceCommissionCurrent() + calculateRetailBonusNew()) / weeksWorked) * data["# weeks paid vacation annually"].Current;
        };

        const calculateVacationNew = () => {
            const weeksWorked = data["# of weeks I work per year"].New;
            if (weeksWorked === 0) {
                return 0;
            }
            return ((calculateServiceCommissionNew() + calculateRetailBonusNew()) / weeksWorked) * data["# weeks paid vacation annually"].New;
        };

        const calculateServiceTipsCurrent = () => {
            const serviceTotalCurrent = data["# of days I work per week"].Current * data["Avg # of daily clients I have"].Current * data["# of weeks I work per year"].Current * data["My avg service ticket $$"].Current;
            const averageTipPercentageCurrent = data["My avg tip %"].Current/100;
            return serviceTotalCurrent * averageTipPercentageCurrent;
        };

        const calculateServiceTipsNew = () => {
            const serviceTotalNew = data["# of days I work per week"].New * data["Avg # of daily clients I have"].New * data["# of weeks I work per year"].New * data["My avg service ticket $$"].New;
            const averageTipPercentageNew = data["My avg tip %"].New/100;
            return serviceTotalNew * averageTipPercentageNew;
        };

        const calculateRetailBonusCurrent = () => {
            return data["My avg retail $ per week"].Current * (data["My avg retail commission %"].Current/100) * data["# of weeks I work per year"].Current;
        };

        const calculateRetailBonusNew = () => {
            return data["My avg retail $ per week"].New * (data["My avg retail commission %"].New/100) * data["# of weeks I work per year"].New;
        };

        const calculateDailyServiceGoalCurrent = () => {
            return data["My avg service ticket $$"].Current * data["Avg # of daily clients I have"].Current;
        };

        const calculateDailyServiceGoalNew = () => {
            return data["My avg service ticket $$"].New * data["Avg # of daily clients I have"].New;
        };

        const calculateDailyRetailGoalCurrent = () => {
            if (data["# of days I work per week"].Current === 0) {
                return 0;
            }
            return data["My avg retail $ per week"].Current / data["# of days I work per week"].Current;
        };

        const calculateDailyRetailGoalNew = () => {
            if (data["# of days I work per week"].New === 0) {
                return 0;
            }
            return data["My avg retail $ per week"].New / data["# of days I work per week"].New;
        };


        const serviceCommissionCurrent = calculateServiceCommissionCurrent();
        const serviceCommissionNew = calculateServiceCommissionNew();
        const vacationCurrent = calculateVacationCurrent();
        const vacationNew = calculateVacationNew();
        const serviceTipsCurrent = calculateServiceTipsCurrent();
        const serviceTipsNew = calculateServiceTipsNew();
        const retailBonusCurrent = calculateRetailBonusCurrent();
        const retailBonusNew = calculateRetailBonusNew();
        const dailyServiceGoalCurrent = calculateDailyServiceGoalCurrent();
        const dailyServiceGoalNew = calculateDailyServiceGoalNew();
        const dailyRetailGoalCurrent = calculateDailyRetailGoalCurrent();
        const dailyRetailGoalNew = calculateDailyRetailGoalNew();
        
        Object.entries(data).map(([category, values]) => (
            data[category] = {
                ...values,
                "Current": values.Current,
                "New": values.New,
                "Difference": values.New - values.Current,
            }
           
        ));

        setProfileData(prevProfileData => ({
            ...prevProfileData,
            "My avg service ticket $$": {
                ...prevProfileData["My avg service ticket $$"],
                "New": data["My avg service ticket $$"].New,
                "Current": data["My avg service ticket $$"].Current,
                "Difference": data["My avg service ticket $$"].New - data["My avg service ticket $$"].Current,
            },
            "Service Commision": {
                ...prevProfileData["Service Commision"],
                "New": serviceCommissionNew,
                "Current": serviceCommissionCurrent,
                "Difference": serviceCommissionNew - serviceCommissionCurrent,
            },
            "Guest Count": {
                ...prevProfileData["Guest Count"],
                "New": data["Avg # of daily clients I have"].New,
                "Current": data["Avg # of daily clients I have"].Current,
                "Difference": data["Avg # of daily clients I have"].New - data["Avg # of daily clients I have"].Current,
            },
            "Vacation": {
                ...prevProfileData["Vacation"],
                "New": vacationNew,
                "Current": vacationCurrent,
                "Difference": vacationNew - vacationCurrent,
            },
            "Service Tips": {
                ...prevProfileData["Service Tips"],
                "New": serviceTipsNew,
                "Current": serviceTipsCurrent,
                "Difference": serviceTipsNew - serviceTipsCurrent,
            },
            "Retail Bonus": {
                ...prevProfileData["Retail Bonus"],
                "New": retailBonusNew,
                "Current": retailBonusCurrent,
                "Difference": retailBonusNew - retailBonusCurrent,
            },
            "Total Income": {
                ...prevProfileData["Total Income"],
                "New": serviceCommissionNew + serviceTipsNew + retailBonusNew + vacationNew,
                "Current": serviceCommissionCurrent + serviceTipsCurrent + retailBonusCurrent + vacationCurrent,
                "Difference": serviceCommissionNew + serviceTipsNew + retailBonusNew - (serviceCommissionCurrent + serviceTipsCurrent + retailBonusCurrent),
            },
            "Daily Service Goal": {
                ...prevProfileData["Daily Service Goal"],
                "New": dailyServiceGoalNew,
                "Current": dailyServiceGoalCurrent,
                "Difference": dailyServiceGoalNew - dailyServiceGoalCurrent,
            },
            "Daily Retail Goal": {
                ...prevProfileData["Daily Retail Goal"],
                "New": dailyRetailGoalNew,
                "Current": dailyRetailGoalCurrent,
                "Difference": dailyRetailGoalNew - dailyRetailGoalCurrent,
            }
            
        }));
    }, [data]);

    const handleInputChange = (category, type, value) => {
        const updatedValue = value === '' ? '' : Number(value);
        const updatedCategory = {
            ...data[category],
            [type]: updatedValue,
            "New": updatedValue,
            "valid": true,
        };

        setData(prevData => ({
            ...prevData,
            [category]: updatedCategory,
        }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    }

    return (
        <div className="outer">
            {screen === "current" && <>
            <div style={{ display: "flex", justifyContent: "center", backgroundColor:"#55959f" }}>
                        <img src={ssbclogo} alt="Summit Salon Business Centre Company Logo" style={{ maxWidth: "45%", padding: "10px 0px" }} />
                    </div>
            <div className="title" style={{backgroundColor: "#35646b"}}>INCOME GROWTH CALCULATOR</div>
            </>}
            {screen === "current" ?
            <>
            <form noValidate onSubmit={(e) => {
    e.preventDefault();
    // Add any additional validation here if necessary
    let isValid = true;
    const newData = { ...data }; // Clone current state

  Object.entries(newData).forEach(([category, values]) => {
    if (values.Current === '') {
      isValid = false;
      newData[category] = { ...values, valid: false }; // Correctly mark as invalid
      
    } else {
      newData[category] = { ...values, valid: true }; 
      console.log(newData[category])// Ensure valid fields are marked as such
    }
  });
  setData(newData);

    if (!isValid) {
      return; // Stop the form submission
    }
    // Your logic to change the screen, assuming validation passes
    setScreen("new");
    window.scrollTo({ top: 0});
}} style={{padding: "10px", backgroundColor:"#D3D3D330", paddingTop:"20px"}}>

    {Object.entries(data).map(([category, values]) => 
        category === "My avg tip %" ? null : (
        <div key={category} className="column-row" style={{display: "flex", flexDirection: "column", justifyContent:"center"}}>
            
            <div style={{width: "100%", display:"flex", justifyContent:"flex-start"}}>
                <h1 className="category-top">{category}*</h1>
            </div>
            <div style={{width: "100%", display:"flex", justifyContent:"center"}}>
            
                <input
                    style={{padding: "10px", borderRadius:"20px", width: "100%", margin: "10px", fontSize: "1.2em"}}
                    type="number"
                    className={`currentInput ${values.valid ? "" : "invalid"}`} // Add the invalid class if the field is invalid
                    value={values.Current === "" ? "" : values.Current}
                    onChange={e => handleInputChange(category, "Current", e.target.value)}
                    required
                />
            </div>
        </div>
    ))}
    
    <div style={{display: "flex", justifyContent: "center"}}>
        <button type="submit" className="submit-button">Next</button>
    </div>

</form></> : <>
                        <div className="stick" style={{ position: "sticky", top: 0, zIndex: 1000}}>
                            <div style={{ backgroundColor: "#55959f", padding: "10px", borderRadius: "0px 0px 20px 20px", display:"flex", justifyContent:"center", flexDirection:"column"  }}>
                                
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0px", marginBottom: "20px" }}>
                                    <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column", padding: "0"}}>
                                        <div style={{display:"flex", justifyContent:"center", marginLeft:"10px", marginRight:"10px"}}>
                                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
                                            <button className="nav-button" onClick={() => {setScreen("current");  window.scrollTo({ top: 0});}}>Back</button>
                                            <img src={ssbclogo} alt="Summit Salon Business Centre Company Logo" style={{ maxWidth: "30%", padding: "10px 0px", marginBottom: "20px" }} />
                                            </div>
                                        </div>
                                        <p style={{ fontSize: "1.2em", color: "white", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "-20px 0px 0px 0px", padding: "0" }}>Goal Income</p>
                                        <p style={{ fontSize: "3em", color: "white", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0" }}>
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(profileData["Total Income"].New)}
                                        </p>
                                        <p style={{ fontSize: ".7em", color: "white", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0" }}>**Assumes 15% Tips**</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", padding: "0", width:"90%", margin: "auto", marginTop:"-20px" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", width: "40%", flexDirection: "column", padding: "0" }}>
                                    <p style={{ fontSize: "1em", color: "white", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", margin: "2px", padding: "0" }}>Current $</p>
                                    <p style={{ fontSize: "1.5em", color: "white", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", margin: "2px", padding: "0" }}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(profileData["Total Income"].Current)}
                                    </p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "40%", flexDirection: "column", padding: "0" }}>
                                    <p style={{ fontSize: "1em", color: "white", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", margin: "2px", padding: "0" }}>Difference</p>
                                    <p style={{ fontSize: "1.5em", color:profileData["Total Income"].Difference >= 0  ? "#8FBC8F" : "#F08080", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", margin: "2px", padding: "0" }}>
                                      {profileData["Total Income"].Difference >= 0  ? "+" : ""}{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(profileData["Total Income"].Difference)}
                                    </p>
                                </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center"}}>
                                    <button className={`nav-button ${screen === 'new' ? 'active-state' : ''}`}  onClick={() => {setScreen("new");
                                     window.scrollTo({ top: 0});}}>Goals</button>
                                    <button className="nav-button" onClick={() => {setScreen("profile");
                                     window.scrollTo({ top: 0});}}>Key Metrics</button>
                                    <button className="nav-button" onClick={() => {setScreen("notes");
                                     window.scrollTo({ top: 0});}}>Notes</button>
                                </div>
                            </div>

            {screen === "profile" && <div style={{margin: "30px, 0px", borderRadius: "30px" }}>
            
            <div className="column-header-bottom">
                <div className="name-title" style={{color: "#55959f" }}></div>
                <p className="current-title" style={{color: "#55959f"}}>Current</p>
                <p className="new-title" style={{color: "#55959f" }}>Goal</p>
                <p className="difference-title" style={{color: "#55959f" }}>+/-</p>
            </div>
            {Object.entries(profileData).map(([category, values]) => 
                category === "Total Income" ? null : (
                <div key={category} className="column-row" style={{backgroundColor: "white", borderRadius: "30px",}}>
                    <h1 className="category-bottom" >{category}</h1>
                    <p className="current" style={{padding:"0", margin: "12px 0px"}}>{new Intl.NumberFormat('en-US', values.style ).format(values.Current)}</p>
                    <p className="new" style={{padding:"0", margin: "12px 0px"}}>{new Intl.NumberFormat('en-US', values.style ).format(values.New)}</p>
                    <p className="difference" style={{margin: "12px 0px", color: values.Difference >= 0  ? "#8FBC8F" : "#F08080"}}>{values.Difference >= 0  ? "+" : ""}{new Intl.NumberFormat('en-US', values.style ).format(values.Difference)}</p>
                </div>
            ))}
            </div>}
            {screen === "notes" && 
            <div style={{background:"none"}} className="notes">
                <p className="lower-title" style={{ width: "95%",fontSize: "2em", fontWeight: "bold", margin:"5px 0px"}}>NOTES</p>
                <textarea
                    value={notes}
                    onChange={handleNotesChange}
                className="notes-text"
                    placeholder="Enter notes here..."
                    name="notes"
                    rows="25"
                    cols="50"
                    style={{ width: "85%", height: "100%", borderRadius:"20px", padding:"20px", marginBottom:"20px", fontSize: "1.2em", resize: "none" , backgroundColor: "#D3D3D330", color: "black", overflow: "auto"}}
                ></textarea>
            </div>}
        </div>
                {screen === "new" && 
            <div style={{padding: "0px", backgroundColor:"white", marginTop:"5px", marginBottom:"20px"}}>
            
            {Object.entries(data).map(([category, values]) =>
            category === "My avg tip %" ? null : (
  <div key={category} className="column-row" style={{ display: "flex", flexDirection: "column", padding: "0px 0px", backgroundColor:"#D3D3D330", borderRadius:"20px", margin:"15px", boxShadow:"3px 3px 10px rgba(0, 0, 0, 0.3)"}}>
    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width:"90%", margin:"auto", marginTop:"0px", marginBottom:"0px" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", margin: "5px 0px" }}>
        <p style={{padding:"0px", marginTop:"12px", marginBottom:"12px", fontSize: "1em"}}>{category}</p>
          
        </div>
        <div style={{ width: "25%", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
        <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px" }}>Diff.</p>
          <p style={{ fontSize: "1em", color: values.Difference >= 0  ? "#8FBC8F" : "#F08080", padding:"2px", margin:"2px", fontWeight:"bold" }}>{values.Difference >= 0  ? "+" : ""}{new Intl.NumberFormat('en-US', values.style).format(values.Difference)}{values.suffix}</p>
        </div>
      </div>
    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", marginBottom:"5px" }}>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width:"90%", margin:"auto"}}> {/* Adjusted padding for slider container */}
          <Slider
          sx={{
            '& .MuiSlider-thumb': {
             marginLeft: '0px', //  Adjust as necessary
            },
            '& .MuiSlider-rail, & .MuiSlider-track': {
              marginLeft: '0px', // Adjust as necessary
            },
          }}
            onChange={e => handleInputChange(category, "New", e.target.value)}
            value={values.New}
            min={values.min}
            max={values.max}
            step={values.step}
            style={{ width: "100%", color: "#55959f", margin:"0px", padding: "0px" }} // Adjusted width to 100% of the new container
          />
        </div>
      <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width:"90%", margin:"auto", marginTop:"5px", marginBottom:"5px" }}>
        <div style={{ width: "25%", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", margin: "5px 0px" }}>
        <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px" }}>Curr.</p>
          <p style={{ fontSize: "1em", color: "#55959f", padding:"2px", margin:"2px" }}>{new Intl.NumberFormat('en-US', values.style).format(values.Current)}{values.suffix}</p>
          
        </div>
        <div style={{ width: "25%", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
        <p style={{ fontSize: "1em", color: "#55959f", padding:"2px", margin:"2px" }}>Goal</p>
          <p style={{ fontSize: "1.4em", color: "#55959f", padding:"2px", margin:"2px", fontWeight:"bold" }}>{new Intl.NumberFormat('en-US', values.style).format(values.New)}{values.suffix}</p>
        </div>
      </div>
    </div>
  </div>
))}


            </div>}
            </>}
        </div>
    );
}

export default Calculator;