import './App.css';
import Calulator from './calc';
import React from 'react';

function App() {
  return (
    <div className="App">
     <Calulator />
    </div>
  );
}

export default App;
